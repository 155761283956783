import { NavLink, useNavigate } from "react-router-dom";
import { ReactComponent as UserGroupIcon } from "../../assets/icons/sidebar-users.svg";
import { ReactComponent as ImageGroupIcon } from "../../assets/icons/sidebar-images.svg";
import { ReactComponent as DashboardIcon } from "../../assets/icons/dashboardIcon.svg";
import { ReactComponent as BillingIcon } from "../../assets/icons/billing.svg";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as QuotaIcon } from "../../assets/icons/quota.svg";
import { ReactComponent as RoleGroupIcon } from "../../assets/icons/role.svg";

import { closeSidebar } from "../../data/slices/settingsSlice";
import { setHeaderTitle } from "../../data/slices/tabsSlice";
import { FaNetworkWired } from "react-icons/fa";
import { SiOpenaccess } from "react-icons/si";
import { RiPriceTag2Line } from "react-icons/ri";
import { HiOutlineRectangleGroup } from "react-icons/hi2";

import { useFlags } from "flagsmith/react";
import { ldap_auth_flag } from "../../constants/flagsmith";

import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { AbilityContext } from "../../context/permissions/Can";

const NavigationLink = ({ icon = null, route = "/", children, title }) => {
  const dispatch = useDispatch();

  return (
    <NavLink
      to={route}
      className="navigation_link"
      onClick={() => {
        dispatch(closeSidebar());
        dispatch(setHeaderTitle(title));
      }}
      data-testid="navigation-link"
    >
      {icon}
      <span className="navigation_link__label">{children}</span>
    </NavLink>
  );
};

const Navigation = () => {
  const flags = useFlags([ldap_auth_flag]);
  const { t } = useTranslation("dashboard");

  const ability = useContext(AbilityContext);

  const { profileOrg } = useSelector((state) => state.auth);

  return (
    <div className="navigation__container" data-testid="navigation-container">
      {ability.can("read", "stats") && (
        <NavigationLink title={t("dashboard-20")} route="/admin/dashboard" icon={<DashboardIcon />}>
          {t("dashboard-20")}
        </NavigationLink>
      )}

      {ability.can("read", "users") && (
        <NavigationLink title={t("dashboard-21")} route="/admin/users" icon={<UserGroupIcon />}>
          {t("dashboard-21")}
        </NavigationLink>
      )}

      {ability.can("read", "roles") && (
        <NavigationLink
          title={t("dashboard-22")}
          route="/admin/user-groups"
          icon={<RoleGroupIcon />}
        >
          {t("dashboard-22")}
        </NavigationLink>
      )}

      {ability.can("read", "imagegroups") && (
        <NavigationLink
          title={t("dashboard-23")}
          route="/admin/images"
          icon={<HiOutlineRectangleGroup color="#667085" />}
        >
          {t("dashboard-23")}
        </NavigationLink>
      )}

      {ability.can("read", "subscriptions") && !profileOrg?.isFromMarketplace && (
        <NavigationLink title={t("dashboard-24")} route="/admin/billing" icon={<BillingIcon />}>
          {t("dashboard-24")}
        </NavigationLink>
      )}

      {ability.can("read", "plans") && !profileOrg?.isFromMarketplace && (
        <NavigationLink
          title={t("dashboard-26")}
          route="/admin/plans"
          icon={<RiPriceTag2Line color="#667085" />}
        >
          {t("dashboard-26")}
        </NavigationLink>
      )}

      {flags?.[ldap_auth_flag]?.enabled && ability.can("manage", "ldap") && (
        <NavigationLink
          title={t("dashboard-25")}
          route="/admin/ldap-config"
          icon={<FaNetworkWired color="#667085" />}
        >
          {t("dashboard-25")}
        </NavigationLink>
      )}

      <NavigationLink
        title={t("dashboard-27")}
        route="/admin/network-policy"
        icon={<SiOpenaccess color="#667085" />}
      >
        {t("dashboard-27")}
      </NavigationLink>

      <NavigationLink title={t("dashboard-28")} route="/admin/quota-managment" icon={<QuotaIcon />}>
        {t("dashboard-28")}
      </NavigationLink>
    </div>
  );
};

export default Navigation;
