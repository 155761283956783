import React from "react";
import ReactDOM from "react-dom/client";
import { store } from "./data";
import { Provider } from "react-redux";
import "./main.scss";
import RoutesProvider from "./routes";
import AuthProvider from "./components/AuthProvider";
import { GoogleOAuthProvider } from "@react-oauth/google";

import "swiper/css";
import "./i18n";

// import * as Sentry from "@sentry/react";

// Sentry.init({
//   dsn: "https://61e2c6d3a2aef2e219d5da6e0813ea9b@sentry.dev.gomydesk.com/7",
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration({
//       maskAllText: false,
//       blockAllMedia: false
//     })
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ["localhost", /^https:\/\/sentry\.dev\.gomydesk\.com\/api/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  // <MetricsProvider
  //   appName="gomydesk"
  //   metricsAggregatorUrl="https://pushgateway.dev.gomydesk.com/metrics/job/my_job"
  //   customMetrics={customPromMetrics}
  // >
  //   <OutsideCallConsumer config={callConfig}>
  <GoogleOAuthProvider clientId="713595650553-dkf7n8dopnot8sih7b7srbd7b177pltf.apps.googleusercontent.com">
    <Provider store={store}>
      <AuthProvider>
        <RoutesProvider />
      </AuthProvider>
    </Provider>
  </GoogleOAuthProvider>
  //   </OutsideCallConsumer>
  // </MetricsProvider>
  // </React.StrictMode>
);
