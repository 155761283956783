import { AbilityBuilder, Ability } from "@casl/ability";

const GetPermissions = (roles, isFromMarketplace) => {
  console.log(isFromMarketplace, "isFromMarketplace");

  const { can, cannot, build } = new AbilityBuilder(Ability);

  const verbs = [
    "read",
    "create",
    "update",
    "delete",
    "invite",
    "launch",
    "subscribe",
    "manage",
    "cancel",
    "readmy",
    "updatemy"
  ];

  let listOfPermissions = [];

  if (isFromMarketplace) {
    can("manage", "all");
    return build();
  }

  roles?.forEach((role) => {
    role?.rules?.forEach((rule) => {
      let key, value;
      key = rule?.resource?.toLowerCase();
      if (rule?.resource === "*" && rule?.verbs?.[0] === "*") {
        value = "manage";
        key = "all";
      } else if (rule?.resource !== "*" && rule?.verbs?.[0] === "*") {
        verbs.forEach((item) => {
          listOfPermissions.push({ value: item, key });
        });
      } else {
        rule?.verbs?.forEach((item) => {
          listOfPermissions.push({ value: item, key });
        });
      }
      if (value) listOfPermissions.push({ value, key });
    });
  });

  listOfPermissions.forEach((item) => {
    if (item?.value === "delete" && item?.key === "users") {
      return;
    }
    can(item?.value, item?.key);
  });

  if (
    listOfPermissions.some(
      (obj) =>
        obj.key === "users" ||
        obj.key === "roles" ||
        obj.key === "serviceaccounts" ||
        obj.key === "imagegroups" ||
        obj.key === "stats" ||
        obj.key === "subscriptions" ||
        obj.key === "plans" ||
        obj.key === "ldap" ||
        obj.key === "invoices" ||
        obj.value === "manage"
    )
  ) {
    can("switch", "admin");
  } else cannot("switch", "admin");

  return build();
};

export default GetPermissions;
